jQuery(document).ready(function($) {
	// fill in the token for the user during the login
	let loginForm = document.querySelector(".essLoginForm");
	if (loginForm) {
		loginForm.addEventListener("submit", ev => {
			// -> block submit input
			ev.target
				.querySelector('input[type="submit"]')
				.setAttribute("disabled", true);

			// -> check if has grecaptcha input
			let captchaInput = ev.target.querySelector("#recaptchaResponse");
			if (captchaInput) {
				ev.preventDefault();
				if (typeof grecaptcha !== "undefined") {
					// -> get grecaptcha token from google
					grecaptcha
						.execute("6Le40rUcAAAAACd6yOc9Hj3awHbF_YVNhXbUb-wQ", {
							action: "login"
						})
						.then(function(token) {
							captchaInput.value = token;
							ev.target.submit();
						});
				} else {
					// -> if grecaptcha scripts aren't loaded yet: just submit the form
					ev.target.submit();
				}
			}
		});
	}

	// trigger the wdmChkPwdStrength
	$("body").on(
		"change",
		".essLoginForm input[name=pass1], .essLoginForm input[name=pass2]",
		function(event) {
			wdmChkPwdStrength(
				// password field
				$("input[name=pass1]"),
				// confirm password field
				$("input[name=pass2]"),
				// strength status
				$("#password-strength"),
				// Submit button
				$("input[type=submit]"),
				// blacklisted words which should not be a part of the password
				["admin", "happy", "hello", "1234"]
			);
		}
	);

	// listen for show password button
	let button = document.querySelector(".passwordInput .passwordInput__toggle");
	if (button) {
		button.addEventListener("click", ev => {
			ev.preventDefault();

			// -> toggle icon
			let icon = ev.currentTarget.querySelector(".fas");
			icon.classList.toggle("fa-eye-slash");
			icon.classList.toggle("fa-eye");

			// -> select target inputs in parent form
			let psw_inputs = ev.currentTarget
				.closest("form")
				.querySelectorAll('input[type="password"]');
			let converted_inputs = ev.currentTarget
				.closest("form")
				.querySelectorAll(".convertedInput");

			// -> toggle password inputs to text inputs
			if (psw_inputs.length) {
				psw_inputs.forEach(input => {
					input.type = "text";
					input.classList.add("convertedInput");
				});
			}

			// -> toggle converted inputs back
			if (converted_inputs.length) {
				converted_inputs.forEach(input => {
					input.type = "password";
					input.classList.remove("convertedInput");
				});
			}
		});
	}
});

function wdmChkPwdStrength(
	$pwd,
	$confirmPwd,
	$strengthStatus,
	$submitBtn,
	blacklistedWords
) {
	var pwd = $pwd.val();
	var confirmPwd = $confirmPwd.val();

	// extend the blacklisted words array with those from the site data
	blacklistedWords = blacklistedWords.concat(
		wp.passwordStrength.userInputBlacklist()
	);

	// every time a letter is typed, reset the submit button and the strength meter status
	// disable the submit button
	$submitBtn.attr("disabled", "disabled");
	$strengthStatus.removeClass("short bad good strong");

	// calculate the password strength
	var pwdStrength = wp.passwordStrength.meter(
		pwd,
		blacklistedWords,
		confirmPwd
	);

	// check the password strength
	switch (pwdStrength) {
		case 2:
			$strengthStatus.addClass("bad").html("Weak");
			break;

		case 3:
			$strengthStatus.addClass("good").html("Average");
			break;

		case 4:
			$strengthStatus.addClass("strong").html("Strong");
			break;

		case 5:
			$strengthStatus.addClass("mismatch").html("Wrong combination");
			break;

		default:
			$strengthStatus.addClass("short").html("Very weak");
	}
	// set the status of the submit button
	if (4 === pwdStrength && "" !== confirmPwd.trim()) {
		$submitBtn.removeAttr("disabled");
	}

	return pwdStrength;
}
